[clickable],
.clickable {
  cursor: pointer;
}

.one-row-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.flex {
    flex: 1;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.custom-bootstrap-container {
  max-width: 1280px;
  margin: 0 auto;
}

.text-underline {
  text-decoration-line: underline;
}

.min-width-200 {
  min-width: 200px;
}
