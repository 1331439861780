






















































































































.v-badge .v-icon {
  color: white;
}

i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: white;
}
.v-list-item--active i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: var(---v-drawerHover-base) !important;
}

.v-list-item--active {
  .v-list-item__title,
  .iconHoverColor {
    color: var(---v-drawerHover-base) !important;
  }
}

#app .v-list .v-list-item--active {
  color: var(--v-drawerHover-base);
}

