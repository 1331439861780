



















































































.panelHeader {
  padding-right: 24px;
  background-color: var(--v-danger-base);
  cursor: initial;
}
.panelContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.textColor {
  text-align: left;
  color: white;
  font-size: 20px;
}
.approveBtn {
  margin: 0 10px;
}
.cancelBtn {
  margin: 0 10px;
}
