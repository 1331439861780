/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}
html {
  //override vuetify
  overflow-y: auto !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

// init from vuetify
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 900;
  //color: #2c3e50;
  .entireDrawer.v-navigation-drawer {
    background: linear-gradient(var(--v-lightblue-base) 25%, var(--v-deepblue-base));
  }
  .navBar {
    background: linear-gradient(to right, var(--v-lightblue-base) 25%, var(--v-deepblue-base));
  }
}

.v-application {
  /*   &--wrap {
    height: 100vh;
  } */

  .v-chip.v-size--default .v-icon {
    height: inherit;
    width: inherit;
  }
}

.v-btn {
  text-transform: initial !important;
}
