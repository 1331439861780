
































































::v-deep .v-dialog--active {
  border-radius: 20px;
}

.v-main {
  height: 100vh;
  background-color: var(--v-drawerHover-base);
}
.popAlert {
  position: fixed;
  bottom: 0px;
  z-index: 900;
  width: 60%;
  left: calc((100% - 60%) / 2);
}
